import axios from "axios";
import baseAPI from "./api.config";

const api = {
    handleLogin: async (username, password) => {
        try {
            const response = await axios.post(`${baseAPI}/api/v1/business-admin/login/`, {
                username,
                password,
            });

            localStorage.setItem('token', response.data.access_token);

            return true;

        } catch (error) {
            return false;
        }
    },
    getOrderAnalytics: async () => {
        try {
            return await axios.get(`${baseAPI}/api/v1/business-admin/order-analytics/`, { headers: { Authorization: "Bearer " + localStorage.getItem("token"), }, });
        } catch (error) { }
    },
    getSellerAnalytics: async () => {
        try {
            return await axios.get(`${baseAPI}/api/v1/business-admin/seller-analytics/`, { headers: { Authorization: "Bearer " + localStorage.getItem("token"), }, });
        } catch (error) { }
    }
};

export default api;