import axios from "axios";

let refresh = false;

axios.interceptors.response.use(resp => resp, async error => {
    console.log('here')
    if (error.response) {
        if (error.response.status === 401 && !refresh && window.location.pathname !== '/login') {
            refresh = true;
            try {
                localStorage.clear();
                if (window.location.pathname !== '/login') {
                    window.location.reload();
                }

            } catch (error) {
                console.log(error);
            } finally {
                refresh = false;
            }
        }
        return error;
    }
});