import styles from './App.module.css';
import MainCards from "./components/MainCards/MainCards";
import RegisterStats from "./components/RegisterStats/RegisterStats";
import StoresStats from "./components/StoresStats/StoresStats";
import Header from "./components/shared/Header";
import OrdersStats from './components/OrdersStats/OrdersStats';
import { useEffect, useState } from 'react';
import api from './api/api';

function App() {

  const [orderStatsData, setOrderStatsData] = useState({ mean: 4323, total: 1283812, boxberry: 82 });
  const [mainData, setMainData] = useState({ users: 298, orders: 0, customers: 0, stores: 209 });
  const [registerData, setRegisterData] = useState({ companies_data: [0, 0, 0, 0, 0, 0, 0], individuals_data: [0, 0, 0, 0, 0, 0, 0] });
  const [storesData, setStoresData] = useState({ individuals: { boxberry: 0, other: 0 }, companies: { boxberry: 0, other: 0 } });

  useEffect(() => {
    api.getOrderAnalytics().then(r => {
      setMainData(prev => {
        return {
          ...prev,
          orders: r.data.orders_count,
          customers: r.data.customers_count,
        }
      });

      setOrderStatsData(prev => {
        return {
          ...prev,
          total: r.data.total_amount / 100,
          mean: Math.floor(r.data.total_amount / 100 / r.data.orders_count),
          boxberry: Math.floor(r.data.boxberry_orders_count / r.data.orders_count * 100),
          orders_line: r.data.orders_last_month,
          boxberry_line: r.data.boxberry_orders_last_month
        }
      })
    });

    api.getSellerAnalytics().then(r => {
      setMainData(prev => {
        return {
          ...prev,
          users: r.data.sellers_count,
          stores: r.data.stores_count,
        }
      });

      setRegisterData(prev => {
        return {
          ...prev,
          companies_data: r.data.companies_data,
          individuals_data: r.data.individuals_data,
        }
      });

      setStoresData(prev => {
        return {
          ...prev,
          individuals: r.data.stores_details.individuals,
          companies: r.data.stores_details.companies,
        }
      });
    })
  }, []);

  return (
    <>
      <Header />
      <div className={styles.container}>
        <div className={styles.metricsContainer}>

          <div className={styles.main}>
            <h1>Основные цифры (<a href="/">Выгрузить в виде таблицы</a>)</h1>
            <MainCards data={mainData} />
          </div>

          <div className={styles.registrations}>
            <h1>Статистика регистраций пользователей за неделю</h1>
            <RegisterStats data={registerData} />
          </div>

          <div className={styles.stores}>
            <h1>Статистика типов доставок по магазинам</h1>
            <StoresStats data={storesData} />
          </div>

          <div className={styles.orders}>
            <h1>Статистика по заказам</h1>
            <OrdersStats data={orderStatsData} />
          </div>
        </div>
      </div>
    </>
  );
}

export default App;
