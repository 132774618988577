import bot from '../../assets/botique_logo.svg';
import box from '../../assets/boxberry_logo.svg';
import styles from './Header.module.css';


function Header() {

    const handleLogout = () => {
        localStorage.clear();
        window.location.reload();
    };

    return (
        <header className={styles.header}>
            <div className={styles.functional}>
                <strong>Botique | Метрики</strong>
                <span onClick={handleLogout}>Выйти</span>
            </div>
            <hr />
            <div>
                <div className={styles.leftSide}>
                    <img src={bot} alt="botique logo" />
                </div>
                <div className={styles.rightSide}>
                    <img src={box} alt="boxberry logo" />
                </div>
            </div>
        </header>
    )
}

export default Header;