import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LineElement,
    LinearScale,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';

import React from 'react';
import { Bar, Line, Pie } from 'react-chartjs-2';
import styles from './OrdersStats.module.css';

ChartJS.register(
    CategoryScale,
    LinearScale,
    ArcElement,
    PointElement,
    LineElement,
    BarElement,
    Title,
    Tooltip,
    Legend
);


function OrdersStats({ data }) {


    // const barData = {
    //     labels: ['Одежда', 'Хоз. товары', 'Техника', 'другое'],
    //     datasets: [
    //         {
    //             label: 'Распределение заказов по категориям',
    //             backgroundColor: 'rgba(54, 162, 235, 0.5)',
    //             borderColor: 'rgba(54, 162, 235, 1)',
    //             borderWidth: 1,
    //             data: [81, 29, 7, 16],
    //             stack: '0'
    //         },
    //     ],
    // };

    // const barOptions = {
    //     plugins: {
    //         tooltip: {
    //             enabled: true,
    //         },
    //         legend: {
    //             display: true
    //         },
    //     },
    //     responsive: true,
    //     interaction: {
    //         intersect: false,
    //     },
    //     scales: {
    //         x: {
    //             stacked: true,
    //         },
    //         y: {
    //             stacked: true,
    //         },
    //     },
    // };

    // const pieData = {
    //     labels: ['Онлайн оплата', 'Другое'],
    //     datasets: [
    //         {
    //             data: [96, 37],
    //             backgroundColor: [
    //                 'rgba(54, 162, 235, 0.5)',
    //                 'rgba(255, 99, 132, 0.5)',
    //             ],
    //             borderColor: [
    //                 'rgba(54, 162, 235, 1)',
    //                 'rgba(255, 99, 132, 1)',
    //             ],
    //             borderWidth: 1,
    //         },
    //     ],
    // };

    // const pieOptions = {
    //     plugins: {
    //         tooltip: {
    //             enabled: true,
    //         },
    //         legend: {
    //             display: true
    //         },
    //     },
    // };

    var lineData;
    var lineOptions;

    if (data.orders_line) {
        const keys = Object.keys(data.orders_line); // Получаем все ключи
        const firstKey = keys[0]; // Получаем первый ключ

        const lineLabels = Array(keys.length).fill('');
        lineLabels[0] = firstKey;
        lineLabels[keys.length - 1] = 'Сейчас';


        lineData = {
            labels: lineLabels,
            datasets: [
                {
                    label: 'Количество заказов',
                    data: Object.values(data.orders_line),
                    borderColor: 'rgb(255, 99, 132)',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                },
                {
                    label: 'Доставок Boxberry',
                    data: Object.values(data.boxberry_line),
                    borderColor: 'rgb(53, 162, 235)',
                    backgroundColor: 'rgba(53, 162, 235, 0.5)',
                },
            ],
        };

        lineOptions = {
            responsive: true,
            // plugins: {},
        };

    }

    // const bar2Data = {
    //     labels: ['Физические лица', 'Юридические лица'],
    //     datasets: [
    //         {
    //             label: 'Boxberry',
    //             backgroundColor: 'rgba(166, 206, 57, 0.5)',
    //             borderColor: 'rgba(166, 206, 57, 1)',
    //             borderWidth: 1,
    //             data: [124, 100],
    //             stack: '0'
    //         },
    //         {
    //             label: 'Самовывоз',
    //             backgroundColor: 'rgba(255, 99, 132, 0.5)',
    //             borderColor: 'rgba(255, 99, 132, 1)',
    //             borderWidth: 1,
    //             data: [42, 33],
    //             stack: '1'
    //         },
    //     ],
    // };

    // const bar2Options = {
    //     plugins: {
    //         title: {
    //             display: true,
    //             text: 'Распределение заказов по типам продавцов и доставок',
    //             position: 'bottom',
    //         },
    //         tooltip: {
    //             enabled: true,
    //         },
    //         legend: {
    //             display: true
    //         },
    //     },
    //     responsive: true,
    //     interaction: {
    //         intersect: false,
    //     },
    //     scales: {
    //         x: {
    //             stacked: true,
    //         },
    //         y: {
    //             stacked: true,
    //         },
    //     },
    // };

    const formatPriceWithThousandSeparator = (price) => {
        return Number(price).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    };

    return (
        <div className={styles.ordersContainer}>
            <div className={styles.cardContainer}>
                <div className={styles.card}>
                    <span>Сумма заказов</span>
                    <span className={styles.count}>{formatPriceWithThousandSeparator(data.total)} ₽</span>
                </div>

                <div className={styles.card}>
                    <span>Средний чек</span>
                    <span className={styles.count}>{formatPriceWithThousandSeparator(data.mean)} ₽</span>
                </div>

                <div className={styles.card}>
                    <span>Доставок Boxberry</span>
                    <span className={styles.count}>{data.boxberry} %</span>
                </div>
            </div>
            {data.orders_line ? <div className={styles.sales}>
                <Line options={lineOptions} data={lineData} />
            </div> : null}
            {/* <div className={styles.categories}>
                <Bar data={barData} options={barOptions} />
            </div>
            <div className={styles.distr}>
                <div><Pie data={pieData} options={pieOptions} /></div>
                <div><Bar data={bar2Data} options={bar2Options} /></div>
            </div> */}
        </div>
    )
}

export default OrdersStats;