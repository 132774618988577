import styles from './MainCards.module.css';
import user from '../../assets/businessman.svg';
import sales from '../../assets/infographic.svg';
import customer from '../../assets/customer.svg';
import sqr from '../../assets/sqr.svg';

function MainCards({ data }) {

    return (
        <div className={styles.cardContainer}>
            <div className={styles.card}>
                <div className={styles.cardHeader}>
                    <img src={user} alt="user" />
                </div>
                <span className={styles.count}>{data.users}</span>
                <span>Продавцов</span>
            </div>

            <div className={styles.card}>
                <div className={styles.cardHeader}>
                    <img src={sqr} alt="user" />
                </div>
                <span className={styles.count}>{data.stores}</span>
                <span>Магазинов</span>
            </div>

            <div className={styles.card}>
                <div className={styles.cardHeader}>
                    <img src={sales} alt="sales" />
                </div>
                <span className={styles.count}>{data.orders}</span>
                <span>Заказов</span>
            </div>

            <div className={styles.card}>
                <div className={styles.cardHeader}>
                    <img src={customer} alt="customer" />
                </div>
                <span className={styles.count}>{data.customers}</span>
                <span>Покупателей</span>
            </div>
        </div>
    )
}

export default MainCards;