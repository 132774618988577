import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';

import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import styles from './RegisterStats.module.css';

ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


function RegisterStats({ data }) {

    const today = new Date();
    const lastSevenDays = [];

    for (let i = 6; i >= 0; i--) {
        const day = new Date(today);
        day.setDate(today.getDate() - i);
        lastSevenDays.push(day.toISOString().split('T')[0]);
    }


    var barData;
    var pieData;

    if (data.individuals_data) {
        barData = {
            labels: lastSevenDays,
            datasets: [
                {
                    label: 'Физические лица',
                    backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    borderColor: 'rgba(255, 99, 132, 1)',
                    borderWidth: 1,
                    data: data.individuals_data,
                    stack: '0'
                },
                {
                    label: 'Юридические лица',
                    backgroundColor: 'rgba(54, 162, 235, 0.5)',
                    borderColor: 'rgba(54, 162, 235, 1)',
                    borderWidth: 1,
                    data: data.companies_data,
                    stack: '1'
                },
            ],
        };

        pieData = {
            labels: ['Физические лица', 'Юридические лица'],
            datasets: [
                {
                    data: [data.individuals_data.reduce((accumulator, currentValue) => accumulator + currentValue, 0), data.companies_data.reduce((accumulator, currentValue) => accumulator + currentValue, 0)],
                    backgroundColor: [
                        'rgba(255, 99, 132, 0.5)',
                        'rgba(54, 162, 235, 0.5)',
                    ],
                    borderColor: [
                        'rgba(255, 99, 132, 1)',
                        'rgba(54, 162, 235, 1)',
                    ],
                    borderWidth: 1,
                },
            ],
        };
    }

    const barOptions = {
        responsive: true,
        interaction: {
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const pieOptions = {
        plugins: {
            legend: {
                display: false
            },
        }
    };

    return (
        <div className={styles.chartContainer}>
            {data.individuals_data ? (
                <>
                    <div className={styles.bar}>
                        <Bar data={barData} options={barOptions} />
                    </div>
                    <div className={styles.pie}>
                        <Pie data={pieData} options={pieOptions} />
                    </div></>
            ) : null}
        </div>
    )
}

export default RegisterStats;