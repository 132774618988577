import {
    ArcElement,
    BarElement,
    CategoryScale,
    Chart as ChartJS,
    Legend,
    LinearScale,
    Title,
    Tooltip,
} from 'chart.js';

import React from 'react';
import { Bar, Pie } from 'react-chartjs-2';
import styles from './StoresStats.module.css';

ChartJS.register(
    CategoryScale,
    ArcElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
);


function StoresStats({ data }) {


    const barData = {
        labels: ['Физические лица', 'Юридические лица'],
        datasets: [
            {
                label: 'Boxberry',
                backgroundColor: 'rgba(166, 206, 57, 0.5)',
                borderColor: 'rgba(166, 206, 57, 1)',
                borderWidth: 1,
                data: [data.individuals.boxberry, data.companies.boxberry],
                stack: '0'
            },
            {
                label: 'Другое',
                backgroundColor: 'rgba(54, 162, 235, 0.5)',
                borderColor: 'rgba(54, 162, 235, 1)',
                borderWidth: 1,
                data: [data.individuals.other, data.companies.other],
                stack: '1'
            },
        ],
    };

    const barOptions = {
        plugins: {
            tooltip: {
                enabled: true,
            },
            legend: {
                display: true
            },
        },
        responsive: true,
        interaction: {
            intersect: false,
        },
        scales: {
            x: {
                stacked: true,
            },
            y: {
                stacked: true,
            },
        },
    };

    const pieData = {
        labels: ['Другое', 'Boxberry'],
        datasets: [
            {
                data: [data.individuals.other + data.companies.other, data.individuals.boxberry + data.companies.boxberry],
                backgroundColor: [
                    'rgba(54, 162, 235, 0.5)',
                    'rgba(166, 206, 57, 0.5)',
                ],
                borderColor: [
                    'rgba(54, 162, 235, 1)',
                    'rgba(166, 206, 57, 1)',
                ],
                borderWidth: 1,
            },
        ],
    };

    const pieOptions = {
        plugins: {
            tooltip: {
                enabled: true,
            },
            legend: {
                display: false
            },
        },
    };

    return (
        <div className={styles.chartContainer}>
            <div className={styles.bar}>
                <Bar data={barData} options={barOptions} />
            </div>
            <div className={styles.pie}>
                <Pie data={pieData} options={pieOptions} />
            </div>
        </div>
    )
}

export default StoresStats;